:root {
  --theme-color: #0da487;
}
@-webkit-keyframes scaleUpDown {
  0%,
  100% {
    -webkit-transform: scaleY(1) scaleX(1);
    transform: scaleY(1) scaleX(1);
  }
  50%,
  90% {
    -webkit-transform: scaleY(1.1);
    transform: scaleY(1.1);
  }
  75% {
    -webkit-transform: scaleY(0.95);
    transform: scaleY(0.95);
  }
  80% {
    -webkit-transform: scaleX(0.95);
    transform: scaleX(0.95);
  }
}
@keyframes scaleUpDown {
  0%,
  100% {
    -webkit-transform: scaleY(1) scaleX(1);
    transform: scaleY(1) scaleX(1);
  }
  50%,
  90% {
    -webkit-transform: scaleY(1.1);
    transform: scaleY(1.1);
  }
  75% {
    -webkit-transform: scaleY(0.95);
    transform: scaleY(0.95);
  }
  80% {
    -webkit-transform: scaleX(0.95);
    transform: scaleX(0.95);
  }
}
@-webkit-keyframes shake {
  0%,
  100% {
    -webkit-transform: skewX(0) scale(1);
    transform: skewX(0) scale(1);
  }
  50% {
    -webkit-transform: skewX(5deg) scale(0.9);
    transform: skewX(5deg) scale(0.9);
  }
}
@keyframes shake {
  0%,
  100% {
    -webkit-transform: skewX(0) scale(1);
    transform: skewX(0) scale(1);
  }
  50% {
    -webkit-transform: skewX(5deg) scale(0.9);
    transform: skewX(5deg) scale(0.9);
  }
}
@-webkit-keyframes particleUp {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: -100%;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}
@keyframes particleUp {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: -100%;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}
@-webkit-keyframes shape {
  0% {
    background-position: 100% 0;
  }
  50% {
    background-position: 50% 50%;
  }
  100% {
    background-position: 0 100%;
  }
}
@keyframes shape {
  0% {
    background-position: 100% 0;
  }
  50% {
    background-position: 50% 50%;
  }
  100% {
    background-position: 0 100%;
  }
}
@-webkit-keyframes rounded {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rounded {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes move {
  0% {
    -webkit-transform: scale(1) rotate(0deg) translate3d(0, 0, 1px);
    transform: scale(1) rotate(0deg) translate3d(0, 0, 1px);
  }
  30% {
    opacity: 1;
  }
  100% {
    z-index: 10;
    -webkit-transform: scale(0) rotate(360deg) translate3d(0, 0, 1px);
    transform: scale(0) rotate(360deg) translate3d(0, 0, 1px);
  }
}
@keyframes move {
  0% {
    -webkit-transform: scale(1) rotate(0deg) translate3d(0, 0, 1px);
    transform: scale(1) rotate(0deg) translate3d(0, 0, 1px);
  }
  30% {
    opacity: 1;
  }
  100% {
    z-index: 10;
    -webkit-transform: scale(0) rotate(360deg) translate3d(0, 0, 1px);
    transform: scale(0) rotate(360deg) translate3d(0, 0, 1px);
  }
}
@-webkit-keyframes mover {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}
@keyframes mover {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}
@-webkit-keyframes flash {
  0% {
    opacity: 0.4;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }
  100% {
    opacity: 1;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }
}
@keyframes flash {
  0% {
    opacity: 0.4;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }
  100% {
    opacity: 1;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }
}
@keyframes shake {
  0% {
    -webkit-transform: translate(3px, 0);
    transform: translate(3px, 0);
  }
  50% {
    -webkit-transform: translate(-3px, 0);
    transform: translate(-3px, 0);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@-webkit-keyframes grow {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes grow {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
body {
  font-family: "Nunito Sans", sans-serif;
  position: relative;
  font-size: 14px;
  color: #222;
  margin: 0;
  background-color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding-right: 0 !important;
}
body ::-moz-selection {
  color: #fff;
  background-color: var(--theme-color);
}
body ::selection {
  color: #fff;
  background-color: var(--theme-color);
}
.ulclass {
  padding-left: 0;
  margin-bottom: 0;
}
.liclass {
  display: inline-block;
  font-size: 14px;
}
p {
  font-size: 14px;
  line-height: 18px;
}
a {
  color: var(--theme-color);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}
a:focus {
  outline: none;
}
button:focus {
  outline: none;
}
.btn-close:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
:focus {
  outline: none;
}
.form-control {
  background-color: #fff;
}
.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: var(--theme-color);
}
h1 {
  font-size: calc(40px + (70 - 40) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 600;
  line-height: 1.1;
  text-transform: capitalize;
  margin: 0;
}
h2 {
  font-size: calc(22px + (28 - 22) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 600;
  line-height: 1;
  text-transform: capitalize;
  margin: 0;
}
h3 {
  font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 500;
  line-height: 1.2;
  margin: 0;
}
h4 {
  font-size: calc(17px + (18 - 17) * ((100vw - 320px) / (1920 - 320)));
  line-height: 1.2;
  margin: 0;
  font-weight: 400;
}
h5 {
  font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
  line-height: 1.2;
  margin: 0;
  font-weight: 400;
}
h6 {
  font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)));
  line-height: 1.2;
  margin: 0;
  font-weight: 400;
}
span {
  display: inline-block;
}
.theme-color {
  color: var(--theme-color) !important;
}
.theme-bg-color {
  background: var(--theme-color) !important;
}
.text-title {
  color: #222 !important;
}
.text-content {
  color: #939393 !important;
}
.btn {
  position: relative;
  padding: 8px 10px;
  z-index: 1;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  border-radius: 0;
  font-weight: bold;
  font-size: 14px;
  text-transform: capitalize;
}
.btn-submit {
  font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
  color: var(--theme-color);
}
.btn-submit:hover {
  background-color: var(--theme-color);
}
.btn-filter {
  font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
  background-color: var(--theme-color);
  color: #fff;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.btn-filter .feather {
  display: block;
  margin-top: -3px;
}
.btn-filter:hover {
  background-color: #cecece;
}
.btn-size {
  font-size: 12px;
}
.btn:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-spacing {
  padding: calc(5px + (14 - 5) * ((100vw - 320px) / (1920 - 320)))
    calc(8px + (30 - 8) * ((100vw - 320px) / (1920 - 320)));
}
.default-white {
  background-color: #fff;
  border-radius: 0;
}
.default-light {
  position: relative;
  background-color: #eee1e6;
  border: 1px solid transparent;
  padding: calc(6px + (14 - 6) * ((100vw - 320px) / (1920 - 320)))
    calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 800;
}
.default-light1 {
  color: var(--theme-color);
  position: relative;
  background-color: unset;
  border: 1px solid transparent;
}
.default-light1::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-color: var(--theme-color);
  opacity: 0.11;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.default-light1::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -1;
  border: 1px solid var(--theme-color);
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.default-light1:hover {
  color: var(--theme-color);
}
.default-light1:hover::before {
  opacity: 0;
  -webkit-transform: scale(0.5, 0.5);
  transform: scale(0.5, 0.5);
}
.default-light1:hover::after {
  opacity: 0.11;
}
.default-light-theme {
  color: var(--theme-color);
  padding: calc(6px + (14 - 6) * ((100vw - 320px) / (1920 - 320)))
    calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
}
.default-light-theme:hover {
  color: var(--theme-color);
}
.default-theme {
  background-color: var(--theme-color);
  color: #fff;
}
.default-theme:hover {
  color: #fff;
}
.default-theme-2 {
  padding: 12px 25px;
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 400;
}
.default-theme-1 {
  -webkit-transform: scale(100%);
  transform: scale(100%);
  border: 1px solid var(--theme-color);
}
.default-theme-1:hover {
  background-color: var(--theme-color);
  color: #fff;
  -webkit-transform: scale(100%);
  transform: scale(100%);
}
.default::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-color: var(--theme-color);
  opacity: 0.11;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.default::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -1;
  border: 1px solid var(--theme-color);
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.default:hover {
  color: var(--theme-color);
}
.default:hover::before {
  opacity: 0;
  -webkit-transform: scale(0.5, 0.5);
  transform: scale(0.5, 0.5);
}
.default:hover::after {
  opacity: 0.11;
}
.btn-light-white {
  padding: 6px 20px;
  color: #fff;
  background-color: unset;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.btn-light-white i {
  font-size: 12px;
}
.btn-light-white::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.btn-light-white:hover {
  color: #fff;
}
.btn-light-white:hover::before {
  opacity: 0;
  -webkit-transform: scale(0.5, 0.5);
  transform: scale(0.5, 0.5);
}
.btn-white {
  padding: 6px 20px;
  background-color: unset;
  border: 1px solid #fff;
}
.btn-white i {
  font-size: 12px;
}
.btn-white::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: #fff;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.btn-white:hover {
  color: #fff;
}
.btn-white:hover::before {
  opacity: 0;
  -webkit-transform: scale(0.5, 0.5);
  transform: scale(0.5, 0.5);
}
.tag-button {
  padding: calc(8px + (14 - 8) * ((100vw - 320px) / (1920 - 320)))
    calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 600;
  z-index: 1;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  border-radius: 0;
  font-weight: bold;
  font-size: calc(12px + (13 - 12) * ((100vw - 320px) / (1920 - 320)));
  border-radius: 5px;
  border: none;
}
.tag-button:hover {
  color: #fff !important;
  background-color: var(--theme-color);
}
.btn-full {
  width: 100%;
  border-radius: 5px;
}
.theme-color {
  color: var(--theme-color);
}
.text-contact {
  color: #646464;
}
.invoice-wrapper {
  background-color: white;
  -webkit-box-shadow: #e6e6e6 0px 0px 14px 3px;
  box-shadow: #e6e6e6 0px 0px 14px 3px;
  padding: 2vh;
}
.invoice-wrapper h2 {
  font-size: 30px;
}
.invoice-wrapper h4 {
  color: #646464;
}
.invoice-wrapper .invoice-detail h5 {
  text-transform: uppercase;
  margin-bottom: 0;
  font-weight: 600;
}
.invoice-wrapper .invoice-detail h6 {
  margin-bottom: 0;
  font-size: 16px;
  color: #6d6d6d;
}
.invoice-wrapper .invoice-footer {
  padding: calc(15px + (45 - 15) * ((100vw - 320px) / (1920 - 320)));
}
.invoice-wrapper .font-bold {
  font-weight: bold;
}

.invoice-wrapper .authorise-sign {
  position: absolute;
  bottom: calc(13px + (40 - 13) * ((100vw - 320px) / (1920 - 320)));
  text-align: center;
}

.invoice-wrapper .authorise-sign h6 {
  margin-bottom: 0;
  font-size: 18px;
  color: black;
  font-weight: bold;
  padding-top: 20px;
  margin-top: 20px;
  line-height: 1;
}
.theme-invoice-1 {
  /* width: 50%; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1vh;
}
.container1 {
  width: 60%;
  padding: 2vh;
}
.col-12_1 {
  width: 100%;
}
.table {
  width: 100%;
}
.ul_data_main_con {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2vh;
}
.ul_data_box {
  width: 50%;
  display: flex;
  /* justify-content: space-between; */
  padding: 1vh;
  gap: 7vh;
}
.ul_data_box .theme-invoice-1 .invoice-wrapper {
  padding: 28px 22px;
}
.theme-invoice-1 .invoice-wrapper .invoice-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.theme-invoice-1 .invoice-wrapper .invoice-header .header-image img {
  width: 200px;
}
.theme-invoice-1 .invoice-wrapper .invoice-header .header-content h3 {
  font-weight: 700;
  font-size: 22px;
}
.theme-invoice-1 .invoice-wrapper .invoice-body {
  margin-top: 23px;
}
.theme-invoice-1 .invoice-wrapper .invoice-body .top-sec .details-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  gap: calc(15px + (50 - 15) * ((100vw - 320px) / (1920 - 320)));
}
@media (max-width: 480px) {
  .theme-invoice-1 .invoice-wrapper .invoice-body .top-sec .details-box {
    display: block;
  }
}
.theme-invoice-1
  .invoice-wrapper
  .invoice-body
  .top-sec
  .details-box
  .address-box {
  background-color: #f7f7f7;
  padding: calc(13px + (18 - 13) * ((100vw - 320px) / (1920 - 320)))
    calc(17px + (30 - 17) * ((100vw - 320px) / (1920 - 320)));
  width: 100%;
}
@media (max-width: 480px) {
  .theme-invoice-1
    .invoice-wrapper
    .invoice-body
    .top-sec
    .details-box
    .address-box:last-child {
    margin-top: 15px;
  }
}
.theme-invoice-1
  .invoice-wrapper
  .invoice-body
  .top-sec
  .details-box
  .address-box
  .ulclass {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 4px;
}
.theme-invoice-1
  .invoice-wrapper
  .invoice-body
  .top-sec
  .details-box
  .address-box
  ul
  li {
  font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 700;
  color: #222;
  width: 100%;
  display: block;
  white-space: nowrap;
}
.theme-invoice-1 .invoice-wrapper .invoice-body .invoice-table {
  margin-top: 25px;
}
.theme-invoice-1 .invoice-wrapper .invoice-body .invoice-table .table {
  border: 1px solid #e2e2e2;
}
.theme-invoice-1
  .invoice-wrapper
  .invoice-body
  .invoice-table
  .table
  > :not(:first-child) {
  border-top: 1px solid #e2e2e2;
}
.theme-invoice-1
  .invoice-wrapper
  .invoice-body
  .invoice-table
  .table
  thead
  tr
  th {
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  border-left: 1px solid #e2e2e2;
  padding: calc(9px + (12 - 9) * ((100vw - 320px) / (1920 - 320)));
  white-space: nowrap;
}
.theme-invoice-1
  .invoice-wrapper
  .invoice-body
  .invoice-table
  .table
  tbody
  tr
  td {
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  color: #939393;
  border-left: 1px solid #e2e2e2;
  padding: 18px;
  white-space: nowrap;
}
.theme-invoice-1 .invoice-wrapper .invoice-body .invoice-table-2 {
  margin-top: 25px;
}
.theme-invoice-1 .invoice-wrapper .invoice-body .invoice-table-2 .table {
  border: 1px solid #e2e2e2;
}
.theme-invoice-1
  .invoice-wrapper
  .invoice-body
  .invoice-table-2
  .table
  > :not(:first-child) {
  border-top: none;
}
.theme-invoice-1
  .invoice-wrapper
  .invoice-body
  .invoice-table-2
  .table
  thead
  tr
  th {
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  background-color: #fff;
  color: #556ee6;
  padding: 12px;
}
.theme-invoice-1
  .invoice-wrapper
  .invoice-body
  .invoice-table-2
  .table
  tbody
  tr:nth-child(even)
  td {
  background-color: #f7f7f7;
}
.theme-invoice-1
  .invoice-wrapper
  .invoice-body
  .invoice-table-2
  .table
  tbody
  tr
  td {
  font-weight: 700;
  vertical-align: middle;
  font-size: 14px;
  /* text-align: center; */
  color: #222;
  border-left: 1px solid #e2e2e2;
  border-bottom: none;
  padding: 9px 18px;
}
.theme-invoice-1
  .invoice-wrapper
  .invoice-body
  .invoice-table-2
  .table
  tbody
  tr
  td
  .item-detail {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.theme-invoice-1
  .invoice-wrapper
  .invoice-body
  .invoice-table-2
  .table
  tbody
  tr
  td
  .item-detail
  li {
  width: 100%;
  white-space: nowrap;
}
.theme-invoice-1 .invoice-wrapper .invoice-body .price-box .ulclasss {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  margin-bottom: 0 !important;
  /* justify-content: flex-end; */
  /* gap: calc(5px + (53 - 5) * ((100vw - 320px) / (1920 - 320))); */
  /* padding: 0 10px; */
}
@media (max-width: 390px) {
  .theme-invoice-1 .invoice-wrapper .invoice-body .price-box .ulclasss {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}
@media (max-width: 360px) {
  .theme-invoice-1 .invoice-wrapper .invoice-body .price-box .ulclasss {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}
.theme-invoice-1 .invoice-wrapper .invoice-body .price-box .ulclasss .liclad {
  font-size: 13px;
  font-weight: 700;
  text-decoration: none;
}
.theme-invoice-1 .invoice-wrapper .invoice-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0;
  margin-top: calc(19px + (40 - 19) * ((100vw - 320px) / (1920 - 320)));
}
@media (max-width: 390px) {
  .theme-invoice-1 .invoice-wrapper .invoice-footer {
    display: block;
  }
}
.theme-invoice-1 .invoice-wrapper .invoice-footer .signature-box {
  display: inline-block;
}
.theme-invoice-1 .invoice-wrapper .invoice-footer .signature-box img {
  display: block;
  width: calc(80px + (96 - 80) * ((100vw - 320px) / (1920 - 320)));
  text-align: center;
  margin: 0 auto;
}
.theme-invoice-1 .invoice-wrapper .invoice-footer .signature-box h5 {
  padding: calc(7px + (12 - 7) * ((100vw - 320px) / (1920 - 320)))
    calc(7px + (12 - 7) * ((100vw - 320px) / (1920 - 320))) 0;
  text-align: center;
  border-top: 1px solid #e2e2e2;
  margin-top: calc(7px + (12 - 7) * ((100vw - 320px) / (1920 - 320)));
}
@media (max-width: 390px) {
  .theme-invoice-1 .invoice-wrapper .invoice-footer .button-group {
    margin-top: 15px;
  }
}
.theme-invoice-1 .invoice-wrapper .invoice-footer .button-group .print-button {
  background-color: #222;
}
.theme-invoice-2 .invoice-wrapper .invoice-header {
  /* background-image: url(../images/shape.png); */
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 86px;
  background-position: center;
  padding: 32px 42px 0;
  margin-bottom: 80px;
}
.theme-invoice-2 .invoice-wrapper .invoice-header .header-contain {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .theme-invoice-2 .invoice-wrapper .invoice-header .header-contain {
    display: block;
  }
}
.theme-invoice-2 .invoice-wrapper .invoice-header .header-contain .header-left {
  /* background-image: url(../images/shape-1.svg); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 42px 50px;
  display: block;
  width: 284px;
}
@media (max-width: 767px) {
  .theme-invoice-2
    .invoice-wrapper
    .invoice-header
    .header-contain
    .header-left {
    padding: 34px 25px;
    width: 235px;
  }
}
@media (max-width: 480px) {
  .theme-invoice-2
    .invoice-wrapper
    .invoice-header
    .header-contain
    .header-left {
    background-color: #f7f7f7;
  }
}
.theme-invoice-2
  .invoice-wrapper
  .invoice-header
  .header-contain
  .header-left
  img {
  width: 150px;
}
@media (max-width: 767px) {
  .theme-invoice-2
    .invoice-wrapper
    .invoice-header
    .header-contain
    .header-left
    img {
    width: 135px;
  }
}
.theme-invoice-2
  .invoice-wrapper
  .invoice-header
  .header-contain
  .header-right
  h3 {
  font-size: calc(24px + (35 - 24) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 800;
  letter-spacing: calc(1.5px + (5 - 1.5) * ((100vw - 320px) / (1920 - 320)));
}
@media (max-width: 767px) {
  .theme-invoice-2
    .invoice-wrapper
    .invoice-header
    .header-contain
    .header-right
    h3 {
    margin-top: 8px;
  }
}
.theme-invoice-2 .invoice-wrapper .invoice-body .top-sec .details-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  gap: 247px;
  padding-left: calc(17px + (24 - 17) * ((100vw - 320px) / (1920 - 320)));
  padding-right: calc(17px + (24 - 17) * ((100vw - 320px) / (1920 - 320)));
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .theme-invoice-2 .invoice-wrapper .invoice-body .top-sec .details-box {
    gap: 85px;
  }
}
@media (max-width: 480px) {
  .theme-invoice-2 .invoice-wrapper .invoice-body .top-sec .details-box {
    display: block;
  }
}
@media (max-width: 480px) {
  .theme-invoice-2
    .invoice-wrapper
    .invoice-body
    .top-sec
    .details-box
    .address-box:last-child {
    margin-top: 15px;
  }
}
.theme-invoice-2
  .invoice-wrapper
  .invoice-body
  .top-sec
  .details-box
  .address-box
  .ulclass {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 4px;
}
.theme-invoice-2
  .invoice-wrapper
  .invoice-body
  .top-sec
  .details-box
  .address-box
  ul
  li {
  font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 700;
  color: #222;
  width: 100%;
  display: block;
  white-space: nowrap;
}
.theme-invoice-2 .invoice-wrapper .invoice-body .body-date .ulclass {
  padding: calc(10px + (18 - 10) * ((100vw - 320px) / (1920 - 320)))
    calc(17px + (25 - 17) * ((100vw - 320px) / (1920 - 320)));
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: #f7f7f7;
  margin-top: 17px;
}
@media (max-width: 530px) {
  .theme-invoice-2 .invoice-wrapper .invoice-body .body-date .ulclass {
    display: block;
  }
}
.theme-invoice-2 .invoice-wrapper .invoice-body .body-date .ulclass li {
  font-size: 13px;
  font-weight: 700;
}
@media (max-width: 530px) {
  .theme-invoice-2 .invoice-wrapper .invoice-body .body-date .ulclass li {
    width: 100%;
  }
}
@media (max-width: 530px) {
  .theme-invoice-2 .invoice-wrapper .invoice-body .body-date .ulclass li + li {
    margin-top: 5px;
  }
}
.theme-invoice-2 .invoice-wrapper .invoice-body .table-borderless {
  padding: 16px;
}
.theme-invoice-2 .invoice-wrapper .invoice-body .table-borderless table {
  border-collapse: collapse;
  width: 100%;
}
.theme-invoice-2
  .invoice-wrapper
  .invoice-body
  .table-borderless
  table
  > :not(:first-child) {
  border: none;
}
.theme-invoice-2
  .invoice-wrapper
  .invoice-body
  .table-borderless
  table
  thead
  tr
  th {
  background-color: var(--theme-color);
  color: #fff;
  border-right: 3px solid #fff;
  text-align: center;
  padding: 10px 17px;
}
.theme-invoice-2
  .invoice-wrapper
  .invoice-body
  .table-borderless
  table
  tbody
  tr:nth-child(even)
  td {
  background-color: #f7f7f7;
}
.theme-invoice-2
  .invoice-wrapper
  .invoice-body
  .table-borderless
  table
  tbody
  tr
  td {
  font-size: 14px;
  font-weight: 700;
  vertical-align: middle;
  text-align: center;
  border: none;
  border-right: 3px solid #fff;
}
.theme-invoice-2
  .invoice-wrapper
  .invoice-body
  .table-borderless
  table
  tbody
  tr
  td
  .table-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.theme-invoice-2
  .invoice-wrapper
  .invoice-body
  .table-borderless
  table
  tbody
  tr
  td
  .table-details
  li {
  font-weight: 700;
  white-space: nowrap;
  font-size: 13px;
}
.theme-invoice-2
  .invoice-wrapper
  .invoice-body
  .table-borderless
  table
  tbody
  tr
  td
  .table-details
  li:last-child {
  font-size: 12px;
}
.theme-invoice-2
  .invoice-wrapper
  .invoice-body
  .table-borderless
  table
  tfoot
  tr
  td {
  background-color: #f7f7f7;
  padding: 15px 10px;
  border: none;
}
.theme-invoice-2
  .invoice-wrapper
  .invoice-body
  .table-borderless
  table
  tfoot
  tr
  td
  .table-price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 60px;
}
.theme-invoice-2
  .invoice-wrapper
  .invoice-body
  .table-borderless
  table
  tfoot
  tr
  td
  .table-price
  li {
  font-weight: 15px;
  font-weight: 800;
}
.theme-invoice-2 .invoice-wrapper .invoice-footer {
  padding-left: 16px;
  padding-right: 16px;
}
@media (max-width: 390px) {
  .theme-invoice-2 .invoice-wrapper .invoice-footer .button-group {
    margin-top: 15px;
  }
}
.theme-invoice-2 .invoice-wrapper .invoice-footer .button-group .ulclass {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}
.theme-invoice-2 .invoice-wrapper .invoice-footer .button-group .print-button {
  background-color: #222;
}
.theme-invoice-3 .invoice-wrapper .invoice-header {
  background-color: #f7f7f7;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  gap: 15px;
}
.theme-invoice-3 .invoice-wrapper .invoice-header .header-left {
  padding: 24px 28px;
  padding-top: 0;
  padding-right: 0;
}
@media (max-width: 480px) {
  .theme-invoice-3 .invoice-wrapper .invoice-header .header-left {
    padding: 24px 28px;
  }
}
.theme-invoice-3 .invoice-wrapper .invoice-header .header-left .header-address {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: calc(20px + (30 - 20) * ((100vw - 320px) / (767 - 320)));
  gap: 46px;
}
@media (max-width: 480px) {
  .theme-invoice-3
    .invoice-wrapper
    .invoice-header
    .header-left
    .header-address {
    display: block;
    width: 100%;
  }
}
.theme-invoice-3
  .invoice-wrapper
  .invoice-header
  .header-left
  .header-address
  > div {
  width: 100%;
}
.theme-invoice-3
  .invoice-wrapper
  .invoice-header
  .header-left
  .header-address
  > div
  ul
  li {
  font-size: 14px;
  font-weight: 700;
}
@media (max-width: 480px) {
  .theme-invoice-3
    .invoice-wrapper
    .invoice-header
    .header-left
    .header-address
    .address-right {
    margin-top: 10px;
  }
}
.theme-invoice-3
  .invoice-wrapper
  .invoice-header
  .header-left
  .header-address
  .address-right
  ul
  li {
  white-space: nowrap;
  display: block;
  width: 100%;
}
.theme-invoice-3
  .invoice-wrapper
  .invoice-header
  .header-left
  .header-address
  .address-left
  ul
  li {
  display: block;
  width: 100%;
}
.theme-invoice-3 .invoice-wrapper .invoice-header .header-right {
  /* background-image: url(../images/shape-3.svg); */
  background-position: bottom;
  padding: 0 19px 29px;
  background-repeat: no-repeat;
  height: 173px;
  margin-right: 28px;
  margin-bottom: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (max-width: 767px) {
  .theme-invoice-3 .invoice-wrapper .invoice-header .header-right {
    display: none;
  }
}
.theme-invoice-3 .invoice-wrapper .invoice-header .header-right h3 {
  color: #fff;
  font-size: 31px;
  font-weight: 800;
  letter-spacing: 1.6px;
}
.theme-invoice-3 .invoice-wrapper .invoice-body {
  padding: 24px;
  padding-bottom: 0;
}
.theme-invoice-3
  .invoice-wrapper
  .invoice-body
  .table-product.table
  > :not(:first-child) {
  border-top: none;
}
.theme-invoice-3 .invoice-wrapper .invoice-body .table-product thead tr th {
  background-color: #f7f7f7;
  color: var(--theme-color);
  padding: 10px 20px;
  text-align: center;
  border: none;
}
.theme-invoice-3
  .invoice-wrapper
  .invoice-body
  .table-product
  tbody
  tr:last-child
  td {
  border-bottom: 1px solid #e2e2e2;
}
.theme-invoice-3 .invoice-wrapper .invoice-body .table-product tbody tr td {
  text-align: center;
  padding: 16px;
  font-size: 14px;
  font-weight: 700;
  border: none;
  white-space: nowrap;
}
.theme-invoice-3 .invoice-wrapper .invoice-body .table-product tfoot tr td {
  border: none;
  color: var(--theme-color);
  font-weight: 800;
  font-size: 15px;
  padding: 16px;
}
.theme-invoice-3 .invoice-wrapper .invoice-footer {
  margin-top: 27px;
  padding: 0;
}
.theme-invoice-3 .invoice-wrapper .invoice-footer .button-group {
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 22px;
}
@media (max-width: 390px) {
  .theme-invoice-3 .invoice-wrapper .invoice-footer .button-group {
    margin-top: 15px;
  }
}
.theme-invoice-3 .invoice-wrapper .invoice-footer .button-group .ulclass {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.theme-invoice-3 .invoice-wrapper .invoice-footer .button-group .print-button {
  background-color: #222;
}
.theme-invoice-3 .invoice-wrapper .invoice-footer .support-box {
  background-color: #f7f7f7;
  padding: calc(12px + (24 - 12) * ((100vw - 320px) / (1920 - 320)))
    calc(19px + (28 - 19) * ((100vw - 320px) / (1920 - 320)));
}
.theme-invoice-3 .invoice-wrapper .invoice-footer .support-box > .ulclass {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .theme-invoice-3 .invoice-wrapper .invoice-footer .support-box > .ulclass {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 9px;
  }
}
.theme-invoice-3
  .invoice-wrapper
  .invoice-footer
  .support-box
  > ul
  > li
  .support-detail {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  gap: 7px;
}
.theme-invoice-3
  .invoice-wrapper
  .invoice-footer
  .support-box
  > ul
  > li
  .support-detail
  .support-icon
  i {
  font-size: calc(20px + (22 - 20) * ((100vw - 320px) / (1920 - 320)));
}
.theme-invoice-3
  .invoice-wrapper
  .invoice-footer
  .support-box
  > ul
  > li
  .support-detail
  .support-content
  ul
  li {
  display: block;
  color: #939393;
  font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 600;
}
/*# sourceMappingURL=style.css.map */
