.bg1 {
  background-color: #f2f6f7;
}
.input_error_ {
  background: #ffe6e6;
  border: 1px solid red !important;
  border-radius: 0.5vh;
}
/* table,
th,
td {
  border: 1px solid black;
} */
/* .tableEngine {
  border: none;
}
.paratable {
  color: #000;
  font-weight: 500;
}
.paratableMain {
  color: #000;
  font-weight: 700;
}
.registrationHead {
  color: #000;
  font-weight: 700;
}
.registrationPara {
  color: #000;
  font-weight: 500;
}

.officeadrs {
  color: #000;
  font-weight: 700;
}
.officeadrs1 {
  color: #000;
  font-weight: 500;
} */

.logo-img {
  width: 58%;
}

.whatsapp_float {
  position: fixed;
  z-index: 99999;
  bottom: 16px;
  left: 16px;
}

.whatsapp_float_btn {
  border-radius: 63%;
  height: 60px;
  width: 60px;
}

.show-menu {
  display: none;
}

.css-o69gx8-MuiCardMedia-root {
  width: none;
}

.head1 {
  font-weight: 400;
  font-size: 15px;
  text-align: justify;
}

.head2 {
  color: white;
  font-weight: 100;
  font-size: 11px;
  line-height: 24px;
}

.error_class {
  border-color: #c42121;
  background: #ffe6e6;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: "red";
  border-radius: 10px;
}

.hello {
  /* margin-left: 153px; */
  /* margin-bottom: -100px; */
}

/* @media screen and (min-width: 480px) {
  .hello {
    margin-left: 160px;
    margin-bottom: -1 00px;
  }
} */

.radio-switch {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 30px;
  background-color: #ccc;
  border-radius: 30px;
  padding: 2px;
  transition: background-color 0.2s;
  /* Add transition for smooth color change */
}

.radio-switch input[type="radio"] {
  display: none;
}

.toggle {
  display: block;
  width: 50%;
  height: 100%;
  background-color: #fff;
  border-radius: 50%;
  transition: transform 0.2s;
}

.radio-switch input[type="radio"]:checked+.toggle {
  transform: translateX(100%);
}

.radio-switch input[type="radio"]:checked+.toggle+.toggle {
  background-color: #2196f3;
  /* Change background color to blue */
}

.checkbox-wrapper-6 .tgl {
  display: none;
}

.checkbox-wrapper-6 .tgl,
.checkbox-wrapper-6 .tgl:after,
.checkbox-wrapper-6 .tgl:before,
.checkbox-wrapper-6 .tgl *,
.checkbox-wrapper-6 .tgl *:after,
.checkbox-wrapper-6 .tgl *:before,
.checkbox-wrapper-6 .tgl+.tgl-btn {
  box-sizing: border-box;
}

.checkbox-wrapper-6 .tgl::-moz-selection,
.checkbox-wrapper-6 .tgl:after::-moz-selection,
.checkbox-wrapper-6 .tgl:before::-moz-selection,
.checkbox-wrapper-6 .tgl *::-moz-selection,
.checkbox-wrapper-6 .tgl *:after::-moz-selection,
.checkbox-wrapper-6 .tgl *:before::-moz-selection,
.checkbox-wrapper-6 .tgl+.tgl-btn::-moz-selection,
.checkbox-wrapper-6 .tgl::selection,
.checkbox-wrapper-6 .tgl:after::selection,
.checkbox-wrapper-6 .tgl:before::selection,
.checkbox-wrapper-6 .tgl *::selection,
.checkbox-wrapper-6 .tgl *:after::selection,
.checkbox-wrapper-6 .tgl *:before::selection,
.checkbox-wrapper-6 .tgl+.tgl-btn::selection {
  background: none;
}

.checkbox-wrapper-6 .tgl+.tgl-btn {
  outline: 0;
  display: block;
  width: 4em;
  height: 2em;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-wrapper-6 .tgl+.tgl-btn:after,
.checkbox-wrapper-6 .tgl+.tgl-btn:before {
  position: relative;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
}

.checkbox-wrapper-6 .tgl+.tgl-btn:after {
  left: 0;
}

.checkbox-wrapper-6 .tgl+.tgl-btn:before {
  display: none;
}

.checkbox-wrapper-6 .tgl:checked+.tgl-btn:after {
  left: 50%;
}

.checkbox-wrapper-6 .tgl-light+.tgl-btn {
  background: #f0f0f0;
  border-radius: 2em;
  padding: 2px;
  transition: all 0.4s ease;
}

.checkbox-wrapper-6 .tgl-light+.tgl-btn:after {
  border-radius: 50%;
  background: #fff;
  transition: all 0.2s ease;
}

.checkbox-wrapper-6 .tgl-light:checked+.tgl-btn {
  background: #9fd6ae;
}

.report-head {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 2px solid #bbb;
}

.report-header-left {
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.report-header-left img {
  height: 100px;
  width: 100px;
}

.report-header-middle {
  width: 70%;
  padding: 0% 5%;
}

.report-header-middletop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text-blue {
  font-size: 15px;
  font-weight: 600;
  color: #00004d;
}

.text-blue-small {
  font-size: 12px;
  font-weight: 600;
  color: #00004d;
}

.dynamic-value {
  font-size: 15px;
  font-weight: 600;
  color: #990000;
}

.dynamic-value-small {
  font-size: 12px;
  font-weight: 600;
  color: #990000;
}

.specific-mainbox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 20%;
  padding: 10px;
}

.specific-box {
  border: 5px solid green;
  width: fit-content;
  padding: 4px 10px;
  border-radius: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.border {
  border-width: 1px;
  border-color: #000;
  margin-top: 5px;
}

.mainborder {
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
}

h3 {
  padding: 2px;
  color: #000;
  font-weight: 500;
  padding-left: 10px;
}

.image-preview-rightbottom-box {
  /* width: 100%; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.image-preview-rightbottom-left {
  width: 90%;
}

.image-preview-rightbottom-right {
  width: 20%;
  /* float: left; */
  /* padding: 10px; */
  align-items: center;
  display: flex;
  justify-content: center;
}

.specific-box span {
  color: green;
}

.specific-title {
  font-weight: 600;
}

.specific-squarebox {
  border: 1.5px solid green;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  width: 20%;
}

.specific-squarebox span {
  font-size: 8px;
}

.specific-squarebox h5 {
  font-size: 22px;
  color: green;
  font-weight: 600;
}

.specific-squarebox p {
  font-size: 14px;
  color: green;
}

.image-preview {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.image-preview-left {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-preview-left img {
  height: 180px;
  width: 200px;
}

.image-preview-right {
  width: 59%;
}

.image-preview-righttop {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.image-preview-rightbottom {
  border: 1px solid #bbb;
  border-radius: 10px;
  font-size: 10px;
  font-weight: 500;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.image-preview-rightbottom-left {
  width: 77%;
  /* border-right: 1px solid #555; */
}

.image-preview-rightbottom-right {
  border-left: 1px solid #bbb;
  /* padding: 5px; */
}

.datentime {
  border-bottom: 1px solid #bbb;
}

.car-spec {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1.5px solid #aaa;
  padding: 10px 20px;
  border-radius: 10px;
  flex-wrap: wrap;
}

.individual-car-spec {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 17%;
  border-right: 1px solid #555;
}

.car-spec-head {
  font-weight: 600;
  color: #555;
}

.car-spec-value {
  font-weight: 700;
  color: #000;
}

.de-dupelist {}

.de-dupe {
  font-size: 9px;
  color: #555;
  font-weight: 600;
}

.vehicle-detail-left {
  width: 100%;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: column;
}

.vehicle-detail-right {
  width: 25%;
}

.vehicle-detail-box {
  /* border: 1px solid #555; */
  border-radius: 10px;
  padding: 10px;
  height: 250px;
  width: 32%;
}

.vehicle-detail-smallbox {
  border-bottom: 1px solid #bbb;
  line-height: 25px;
}

.vehicle-detail-right {
  /* border: 1px solid #555;
  margin: 0px 3%; */
  border-radius: 10px;
  padding: 1% 2%;
  height: 250px;
}

.car-spec-footer-left {
  width: 60%;
}

.car-spec-footer-middle,
.car-spec-footer-right {
  width: 20%;
  border-left: 1px solid #555;
  /* background-color: #000066; */
  text-align: center;
}

.car-spec-footer {
  padding: 5px 10px;
  border-radius: 10px;
  margin-top: 10px;
  border: 1px solid #555;
}

.remark-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
}

.remark-box-left {
  border: 1px solid #555;
  border-radius: 10px;
  width: 70%;
  height: 90px;
  padding: 10px 15px;
}

.remark-box-right {
  border-radius: 10px;

  margin-left: 3.5%;
  height: 90px;
  padding: 10px 15px;
}

.service-by {
  border: 1px solid #555;
  padding: 10px 15px;
  border-radius: 10px;
}

.font-weight-bold {
  font-weight: 600;
  color: #333;
  font-size: 13px;
}

.service-boy {
  font-size: 12px;
  font-weight: 600;
  color: #555;
}

.office-location {
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
  background-color: #f8f8ff;
  border: 1px solid #555;
  padding: 10px 20px;
}

.office-location-left {
  width: 15%;
}

.office-location-middle {
  width: 70%;
}

.car-img {
  height: 200px;
  width: 100%;
  border-radius: 4px;
}

.disclaimer-list {
  list-style: circle;
}

.disclaimer-list-li,
.disclaimer-list-li-sign {
  font-size: 12px;
  color: #555;
  font-weight: 600;
}

.disclaimer-list-li-sign {
  margin-top: 60px;
  text-align: end;
}

@media screen and (max-width: 998px) and (min-width: 768px) {
  .specific-mainbox {
    width: 22%;
    padding: 10px;
    margin: 5px;
  }

  .image-preview-rightbottom-left {
    width: 78%;
  }

  .car-spec {
    padding: 10px 6px;
  }

  .individual-car-spec {
    font-size: 12px;
  }

  .car-spec-head {
    font-size: 13px;
  }

  .car-spec-value {
    font-size: 12px;
  }

  .vehicle-detail-box {
    height: 270px;
  }

  .vehicle-detail-right {
    height: 270px;
  }
}

@media screen and (max-width: 768px) and (min-width: 520px) {
  .image-preview {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }

  .image-preview-left {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .image-preview-left img {
    width: 100%;
    height: 240px;
  }

  .image-preview-right {
    width: 100%;
  }

  .image-preview-righttop {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    flex-wrap: wrap;
  }

  .specific-mainbox {
    width: 20%;
  }

  .car-spec:last-child {
    width: 34%;
  }

  .individual-car-spec {
    width: 24%;
    font-size: 13px;
  }

  .specific-title {
    font-size: 12px;
  }

  .specific-squarebox {
    width: 33%;
  }

  .image-preview-rightbottom-left {
    width: 78%;
  }

  .car-spec {
    padding: 10px 6px;
  }

  .individual-car-spec {
    font-size: 12px;
  }

  .car-spec-head {
    font-size: 13px;
  }

  .car-spec-value {
    font-size: 12px;
  }

  .vehicle-detail-box {
    height: 270px;
  }

  .vehicle-detail-right {
    height: 270px;
  }
}

/* new report release css =================================== */

.content {
  /* width: 210mm; 
 height: 297mm;  */
  margin: 0 auto;
  padding: 10mm;
  background-color: white;
  box-sizing: border-box;
}

.page-break {
  page-break-after: always;
}

.newReport {
  width: 100%;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 210mm;
  height: 30mm;
  background-color: #e4e4e4;
  color: #000;
  text-align: center;
  padding: 10px 0;
}

tr td.subtitle {
  color: #f00;
}

.top-bar {
  height: 85px;
  border: 1px solid #777;
  width: 100%;
}

.coname {
  color: #242424;
  padding: 10px 0 0 25px;
}

.userarea {
  color: #242424;
  padding: 15px 10px 0 0px;
  font-size: 12pt;
}

::placeholder {
  font-size: 9pt;
  color: #e8e8e8;
  font-weight: normal;
}

.btn-success {
  margin: 0 5px;
  background: #888;
}

.imgfix img {
  position: relative;
  /* Position the image relative to its parent */
  top: 0;
  /* Position the image at the top of the parent div */
  left: 0;
  /* Position the image at the left of the parent div */
  width: 100%;
  /* Make the width of the image fill the parent div */
  height: 280px;
  /* Make the height of the image fill the parent div */
  object-fit: cover;
  /* Maintain aspect ratio while covering the parent div */
}

.row,
.col-sm-4,
.col-sm-6,
.col-sm-8 {
  padding-left: 0;
  padding-right: 0;
  display: flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h4 {
  padding: 0 !important;
}

.fixed-img {
  position: relative;
  width: 330px;
  height: 280px;
  border: 1px solid #000;
}

.small-image {
  height: 78px;
  width: 78px;
}

@media print {
  .column-ht {
    /* background-image: url('img/scroll.jpg');  */
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 300px;
    /* Adjust height as needed */
    padding-top: 3px;
    padding-left: 3px;
  }

  .noprint {
    display: none;
  }
}

.column-ht {
  /* background-image: url('img/scroll.jpg');  */
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 205px;
  /* Adjust height as needed */
  /* padding-top: 20px; */
  padding-left: 20px;
}

table tr td.bg-column {
  /* Specify the URL of your background image */
  /* background-image: url('img/eng.png'); */
  background-size: cover;
  /* Adjust how the background image is sized */
  background-position: center;
  /* Adjust the position of the background image */
  background-repeat: no-repeat;
  /* Ensure the background image doesn't repeat */
}

.legtext {
  font-size: 13px;
  font-weight: bold;
  text-align: center;
}

hr {
  padding: 0 !important;
}

.fixed-height-column td {
  height: 100px;
  /* Set the fixed height here */
}

.legcontainer {
  position: relative;
  top: 0;
  left: 0;
  width: 90px;
  height: 90px;
}

.image1 {
  position: absolute;
  top: 25px;
  left: 25px;
  width: 30%;
  height: 3 0%;
  z-index: 1;
}

.image2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.full-width-row {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.imgcontainer {
  position: relative;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
}

.image3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.fixed-img {
  position: relative;
  width: 310px;
  height: 260px;
}

table.rowheight tr {
  height: 10px;
  border-bottom: 1px solid #686868;
}

table.rcrowheight {
  height: 10px;
}

.rtborder tr td {
  border: 1px solid #686868;
  padding: 0 5px;
}

.small-logo {
  position: absolute;
  top: 5px;
  /* Adjust as needed */
  left: 5px;
  /* Adjust as needed */
  z-index: 1;
  /* Higher value means closer to the top */
}

.center-round {
  display: flex;
  justify-content: center;
  align-items: center;
}

.download-image {
  background-color: #ff6600;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50%;
  position: absolute;
  right: 5%;
  top: 5%
}

.upload-image {
  background-color: #ff6600;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50%;
  position: absolute;
  left: 5%;
  top: 5%
}

.menu-Bg {
  background-color: #fff !important;
  color: #4d44b5 !important
}

.menuBg {
  color: #fff !important
}

.textfield {
  width: 100% !important;
}

.textfieldcontainer {
  margin-top: 7px !important;
  width: 100%;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  height: 5px !important;
  width: 100%;
  overflow: hidden;
  font-size: 14px !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  /* height: 5px !important; */
  width: 100%;
  overflow: hidden;
  font-size: 14px !important;
  /* margin-top: 5px; */
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 12px !important;
  color: #4d44b5 !important;
}
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root{
  color: #4d44b5 !important;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #4d44b5 !important
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding-right: 32px;
  height: 6px !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  min-height: 6px !important;

}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input::placeholder {
  font-size: 12px !important;
}

.textfield .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #00b33c !important;
  border-width: .3px;
}

.textfield .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #00b33c !important;
  border-width: .3px;
  /* border-bottom-color:  #0e947a; */
}

/* .textfield .MuiOutlinedInput-root:hover .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: #47d147 !important;
} */

/* .textfield .MuiOutlinedInput-root.Mui-focused .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: #47d147 !important;
} */

.textfield .MuiInputLabel-root.Mui-focused {
  color: #00b33c !important;
}

.dashboard-back:hover {
  box-shadow: rgba(77, 68, 181, .2) 0px 10px 10px 0px;
}

.form-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-flex-start{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 70px;
}
.css-1x5jdmq{
  height: 38px !important;
  padding: 0px 5px !important;
}
.css-qiwgdb.MuiSelect-select{
  min-height: 5px !important;
  height: 5px !important;
}